.contact {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem 0;
  text-align: center;
  width: 100%;
  color: #595555;
  justify-content: center;
  max-width: 960px;
  margin: 0 auto;

  h2 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.2;
    width: 100%;
    margin-bottom: 1rem;
  }

  span {
    width: 100%;
    font-size: 18px;
    max-width: 800px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 560px;
    gap: 2rem;
    margin-top: 3rem;

    .col {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;

      label, input {
        width: 100%;
        text-align: left;
      }

      label {
        font-size: 16px;
      }

      input {
        font-size: 1rem;
        background-color: #fff;
        height: 48px;
        border-radius: 24px;
        outline: none;
        border: none;
        padding: .5rem 1rem;
      }
    }

    .error {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      color: #f60d0d;
    }
  }
}

@media (max-width: 500px) {
  .contact {
    padding: 3rem 0;
    border-top: 1px solid #ddd;
  }
}
