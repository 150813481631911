#home {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  #home {
    padding: 0 2rem;
  }
}
