.button {
  width: fit-content;
  padding: 0 12px;
  background-color: #f60d0d;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  height: 48px;
  border-radius: 1.25rem;
  text-decoration: none;
  font-size: 16px;

  &.loading {
    background-color: rgb(107, 109, 131) !important;
    cursor: wait;
  }
}
