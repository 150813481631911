#accountDeactivation {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;

  .header {
    font-size: 28px;
    font-weight: 500;
    color: #f60d0d;
    margin-bottom: 10px;
  }

  .lastUpdate {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: justify;
    padding: 30px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }

    b {
      font-weight: 500;
    }

    h5 {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
    }

    a {
      color: #f60d0d;
      &:visited {
        color: #f60d0d;
      }
    }

    ul {
      list-style: none;
      margin: 14px 0;
      position: relative;

      li {
        display: flex;
        align-items: center;
        height: 24px;
        padding-left: 20px;
        &:before {
          content: '•';
          position: absolute;
          left: 0;
          font-size: 24px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  #accountDeactivation {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media (max-width: 500px) {
  #accountDeactivation {
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    li {
      height: auto !important;
      align-items: flex-start !important;
    }
  }
}
