.hero {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  flex-wrap: wrap;
  padding: 5rem 0;
  color: #595555;
  max-width: 960px;
  margin: 0 auto;

  h1 {
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 1.1;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2.5rem;
    justify-content: center;

    span {
      font-size: 18px;
    }
  }

  .heroImageContainer {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .content {
    text-align: center;
  }
  .buttonContainer {
    align-self: center;
  }
  .heroImageContainer {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .hero {
    padding: 2rem 0;
    h1 {
      font-size: 2.5rem;
    }
    span {
      line-height: 1.6rem;
    }
  }
  .heroImageContainer {
    img {
      width: 100%;
      height: auto;
      max-width: 100%;
    }
  }
}
