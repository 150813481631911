.footer {
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  align-items: center;
  width: 100%;
  background: black;

  .content {
    display: flex;
    flex-direction: column;
    width: 860px;
    margin: 0 auto;
  }

  .intl {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px 0;
    border-top: 1px solid rgba(128, 128, 128, 0.35);
    border-bottom: 1px solid rgba(128, 128, 128, 0.35);
    margin-bottom: 20px;
    color: white;
    font-size: 14px;
  }

  .columns {
    display: flex;
    justify-content: space-between;
    width: 100%;

    ul.destinationLinks {
      flex: 1;
      display: flex;
      flex-direction: column;
      list-style: none;

      li {
        display: flex;
        justify-content: flex-end;
        height: 24px;
        margin-bottom: 10px;

        a {
          font-size: 14px;
          text-decoration: none;
          color: white;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .contactInfo {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    justify-content: space-between;

    .contactBlock {
      display: flex;
      flex-direction: column;
      font-size: 14px;
    }

    label {
      color: white;
    }

    p {
      color: #737373;
    }
  }

  p {
    width: 100%;
    font-size: 14px;
    color: #595555;
  }

  .copyright {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 900px) {
  .footer {
    padding: 60px 32px;
    .content {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .footer {
    .columns {
      flex-direction: column;
    }
    .destinationLinks {
      align-items: flex-end;
      margin-top: 40px;
    }
  }
}
