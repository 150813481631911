.title {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.notFoundContainer {
  margin: 40px 0;
  text-align: center;

  span {
    color: rgba(0,0,0,0.6);
    font-style: italic;
  }
}

.notFound {
  font-size: 120px;
  line-height: 120px;
  font-weight: 400;
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}