@font-face {
  font-family: Fredoka;
  src: url(assets/fonts/Fredoka-VariableFont.ttf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Fredoka, -apple-system, sans-serif;
}

html {
  background: #000;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFAEB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
