.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  width: 100%;
}

@media (max-width: 1024px) {
  .menu {
    padding: 1rem 2rem;
  }
}
