.ourTeam {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem 0;
  text-align: center;
  width: 100%;
  color: #595555;
  justify-content: center;
  max-width: 960px;
  margin: 0 auto;

  h2 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.2;
    width: 100%;
    margin-bottom: 1rem;
  }

  .memberList {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;
    gap: 20px;
    margin-top: 2rem;
  }

  .memberItem {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    span {
      font-size: 15px;
      opacity: 0.8;
    }
  }

  .memberImageContainer {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 500px) {
  .ourTeam {
    padding: 3rem 0 3rem 0 !important;
    margin-top: 2rem;
    border-top: 1px solid #ddd;
  }
  .memberList {
    gap: 60px !important;
  }
}
