.toastContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: -100px;
  padding: 0 20px;
  left: 0;
  transition: bottom 0.5s ease-in-out;

  .toast {
    width: fit-content;
    background-color: #404252;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 14px;
    border-radius: 10px;
    font-weight: 300;
    border-bottom: 3px solid #50c879;
    box-shadow: 0 4px 10px rgba(64, 66, 82, 0.4);
  }

  .iconContainer {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #50c879;
    border-radius: 50%;
  }

  &.shown {
    bottom: 40px
  }
}
